/* src/components/layout/Footer.css */
.footer {
    background-color: #363f45;
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.contact-info,
.copyright-info {
  font-size: large;
  color: #e2dfdf;
  margin-top: 0.5rem;
}

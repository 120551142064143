.follow-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #FF385C;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

.follow-button.following {
  background-color: #A5A5A5; /* A gray color to indicate already following */
  color: black; /* Change text color to black for contrast */
}

.follow-button:hover {
  background-color: #E83150;
}

.follow-button.following:hover {
  background-color: #949494; /* A darker shade of gray for hover on 'Following' */
}

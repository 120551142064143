

.follow-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ececec;
  background-color: #fff;
}

.follow-list-item.unread {
  background-color: #f9f9f9;
}
.follow-list-item.read {
  background-color: #f9f9f9;
  color: #919191;
}

.follow-list-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.follow-list-info {
  flex-grow: 1;
  p {
    margin: 0;
    color: #333;
  }
}

.follow-list-text {
  display: flex;
  flex-flow: row;
  column-gap: 0.5rem;
}

.follow-list-name {
  display: flex;
  flex-flow: row;
}
.follow-list-name:hover {
  color: #17a2b8;
}

.follow-list-time {
  margin-left: auto;
  padding-left: 10px;
  margin-right: 1rem;
  color: #999;
}

.follow-list-follow-button  {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  zoom: 0.8;
}


@media (max-width: 700px) {

}



/* Comment.module.css */
.commentContainer {
  margin-bottom: 1rem;
  padding: 12px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}

.header {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.userName {
  font-weight: bold;
  color: #333;
}

.date {
  margin-left: auto;
  color: #aaa;
  font-size: 0.85em;
  margin-right: 20px;
}

.commentText {
  margin-top: 8px;
  line-height: 1.4;
  color: #666;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.actionButtonList {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
} 

.likeButton {
  cursor: pointer;
  border: none;
  background: none;
}

.viewReplies {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  border: none;
  background: none;
}

/* Transition effects for collapsing */
.collapseTransition {
  transition: max-height 0.2s ease-out, padding 0.2s ease-out;
  overflow: hidden;
}

.expand {
  animation: expand 0.3s ease-out;
  animation-fill-mode: forwards;
}

.collapse {
  animation: collapse 0.3s ease-out;
  animation-fill-mode: forwards;
}

@keyframes expand {
  0% {
    max-height: 0;
    padding: 0;
  }
  100% {
    max-height: 1000px;
    padding: 12px;
  }
}

@keyframes collapse {
  0% {
    max-height: 1000px;
    padding: 12px;
  }
  100% {
    max-height: 0;
    padding: 0;
  }
  
}


.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.messages {
  margin-top: 150px;
  overflow-y: auto;
  margin-bottom: 80px;
  padding-bottom: 10px;
  flex-grow: 1;
  @media screen and (max-width: 768px){
    margin-top: 120px;
  }
}

.userMessage {
  align-self: flex-end;
  background-color: #dcf8c6; /* 淡绿色背景，类似WhatsApp用户消息 */
  padding: 5px 10px;
  border-radius: 8px;
  margin: 5px;
  order: -1; /* 使用户消息显示在底部 */
}

.botMessage {
  align-self: flex-start;
  background-color: #ffffff; /* 白色背景 */
  padding: 5px 10px;
  border-radius: 8px;
  margin: 5px;
  white-space: pre-wrap; /* Maintains whitespace and wraps text */
  word-wrap: break-word; /* Ensures long words do not overflow */
}

.messageInputArea {
  position: fixed; /* 固定在底部 */
  bottom: 20px;
  width: 78%;
  padding-right: 50px; /* 为按钮留出空间 */
  z-index: 1000;
  @media screen and (max-width: 1000px) {
    position: fixed; /* 固定在底部 */
    width: 100%; /* 在大屏幕上缩小输入框的宽度 */
    bottom: 70px;
  }
}

.messageInput {
  width: 100%;
  padding: 12px 50px 15px 10px; /* 上右下左 */
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 20px;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: #6c757d 0px 0px 5px;
  resize: none; /* 防止textarea可调整大小 */
  outline: none; /* 移除焦点时的边框 */
  height: 50px; /* 输入框的高度 */
  max-height: 100px;
  overflow-y: auto;
  z-index: 1000;
}

.messageInput::-webkit-scrollbar {
  width: 0;
}


.sendButton {
  position: absolute;
  right: 55px; /* 距离父元素的右边距 */
  bottom: 12px; /* 距离父元素的底边距 */
  background-color: var(--primary-light-color); /* Bootstrap蓝色 */
  border: none;
  border-radius: 40%;
  width: 40px; /* 按钮的大小 */
  height: 40px; /* 按钮的大小 */
  cursor: pointer;
  outline: none; /* 移除焦点时的边框 */
}
.sendButton:hover {
  background-color: var(--primary-color); /* 深蓝色 */
}

.sendButtonIcon {
  color: white;
  font-size: 24px; /* 图标的大小 */
}

.sendButton:disabled {
  background-color: #6c757d; /* 禁用时的颜色 */
}

.backButton {
  position: absolute;
  left: -50px; /* 距离父元素的左边距 */
  bottom: 12px; /* 距离父元素的底边距 */
  background-color: #ccc; /* 设置为不同于发送按钮的颜色 */
  border: none;
  border-radius: 50%;
  width: 40px; /* 按钮的大小 */
  height: 40px; /* 按钮的大小 */
  cursor: pointer;
  outline: none; /* 移除焦点时的边框 */
  @media screen and (max-width: 768px){
    display: none;
  }
}

.backButton:hover {
  background-color: #bbb; /* 鼠标悬停时的颜色变化 */
}

.messageWithAvatar {
  display: flex;
  align-items: flex-start; /* 对齐到顶部 */
}

.userAvatar, .botAvatar {
  flex-shrink: 0; /* 防止缩小 */
  flex-basis: auto; /* 根据内容大小调整 */
  width: 30px; /* 固定宽度 */
  height: 30px; /* 固定高度 */
  border-radius: 50%;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes pulse {
  0%, 80%, 100% {
    transform: scale(0.8);
  }
  40% {
    transform: scale(1.2);
  }
}

.loadingDot {
  display: inline-block;
  margin: 0 2px;
  color: #888; /* 灰色 */
  font-family: Arial, sans-serif;
  font-size: 24px;
  animation: pulse 0.6s infinite ease-in-out;
}

.loadingDot:nth-child(1) {
  animation-delay: 0s;
}

.loadingDot:nth-child(2) {
  animation-delay: 0.2s;
}

.loadingDot:nth-child(3) {
  animation-delay: 0.4s;
}

.messageText {
  white-space: pre-wrap; /* Maintains whitespace and wraps text */
  word-wrap: break-word; /* Ensures long words do not overflow */
}

@media screen and (max-width: 1000px) {
  .messages {
    margin-top: 120px;
  }
  .messageInputArea {
    position: fixed;
    width: 100%;
    bottom: 70px;
  }
}

@media screen and (max-width: 768px) {
  .messages {
    margin-top: 120px;
  }
  .backButton {
    display: none;
  }
}
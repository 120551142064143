

.notification-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ececec;
  background-color: #fff;
}

.notification-item.unread {
  background-color: #f9f9f9;
}
.notification-item.read {
  background-color: #f9f9f9;
  color: #919191;
}

.notification-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.notification-info {
  flex-grow: 1;
  p {
    margin: 0;
    color: #333;
  }
}

.notification-text {
  display: flex;
  flex-flow: row;
  column-gap: 0.5rem;
}

.notification-name {
  display: flex;
  flex-flow: row;
  color: #000;
}

.notification-name-read {
  color: #919191;
}

.notification-name:hover {
  color: #17a2b8;
}


.notification-time {
  margin-left: auto;
  padding-left: 10px;
  margin-right: 1rem;
  color: #999;
}

.notification-follow-button  {
  zoom: 0.8;
}

.notification-post-thumbnail img {
  width: 50px; /* adjust as needed */
  height: 50px; /* adjust as needed */
  border-radius: 10%;
  object-fit: cover;
}


@media (max-width: 700px) {

}



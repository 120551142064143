
.profiles-icon-medium {
  width: 3.5rem;
  height: 3rem;
  cursor: pointer;
}
.profiles-icon-medium img{
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.profiles-list-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  margin-bottom: 1rem;
}

.profiles-list-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3rem;
  font-family: 'Futura';
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  column-gap: 0.5rem;
  line-height: 1.2;
}

.profiles-list-header-search-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 700px) {

  .profiles-list-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    column-gap: 0.5rem;
    line-height: 1.2;
  }

  .profiles-icon-medium {
    width: 2.4rem;
    height: 2rem;
    cursor: pointer;
  }

}
.chatList {
  list-style-type: none;
  padding: 0;
}

.chatItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: #f3f3f3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.5s ease-out;
}

.chatItem:hover {
  background-color: #e9ecef;
}

.chatTitle {
  font-weight: bold;
  color: var(--primary-color);
  white-space: nowrap; /* 确保文本不会换行 */
  overflow: hidden; /* 超出部分不显示 */
  text-overflow: ellipsis; /* 超出部分显示为省略号 */
  display: inline-block;
}

.linkStyle {
  width: 60%;
  max-width: 60%;
  color: inherit;
}

.titleInput {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.actions button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: var(--primary-light-color);
  color: white;
  cursor: pointer;
}

.actions button:hover {
  background-color: var( --primary-color);
}


.actions button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.actions button.lightButton {
  background-color: #f8f9fa;
  color: #6c757d;
  border: 1px solid #6c757d;
}

.actions button.lightButton:hover {
  background-color: #e2e6ea;
  color: #5a6268;
}

/* 添加按钮间隔，以便它们不会挨得太紧 */
.actions button {
  margin-left: 10px;
}

/* 隐藏button的默认边框和背景 */
button {
  border: none;
  background: none;
  cursor: pointer;
}

.actions .trashButton {
  background-color: #dc3545;
}

.actions .trashButton:hover {
  background-color: #bd2130;
}

.chatRole {
  font-weight: bold;
  margin-right: 10px;
}

.chatContent {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap; /* Maintains whitespace and wraps text */
  word-wrap: break-word; /* Ensures long words do not overflow */
}

.roleSelect {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.contentInput {
  width: 80%;
  height: 100px;
  padding: 5px;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  white-space: pre-wrap; /* Maintains whitespace and wraps text */
  word-wrap: break-word; /* Ensures long words do not overflow */
  overflow-y: scroll;
}

.editingSection {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.addButton {
  width: 100%;
  height: 100px; /* 设置按钮的高度 */
  /* background-color: #28a745; */
  background: linear-gradient(45deg, rgba(40,167,69, 1) 40% ,rgba(40,167,69,0.5) 50% ,rgba(40,167,69,1) 60%); /* 渐变背景 */
  background-size: 200% 100%;
  background-position-x: 120%;
  color: white;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px; /* 与列表项的间距 */
  font-size: 24px; /* 大一点的字体大小 */
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  cursor: pointer;
  animation: loading 1.5s ease-in-out infinite;
}

.addButton:hover {
  background: linear-gradient(45deg, rgba(33,136,56, 1) 40% ,rgba(33,136,56,0.7) 50% ,rgba(33,136,56,1) 60%);
  background-size: 200% 100%;
  background-position-x: 120%;
  animation: loading 1s ease-in-out infinite;
}

.dateHeader {
  font-weight: bold;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

@media (max-width: 768px) { /* 假设768px是你的手机屏幕断点 */
  .chatItem {
    flex-wrap: wrap;
  }

  .chatTitle, .linkStyle {
    max-width: 40%; /* 或者根据需要调整 */
    flex: 1; /* 允许标题占据剩余空间 */
  }

  .actions {
    flex-shrink: 0; /* 防止按钮被挤压 */
  }
}

.chatTitle {
  /* ...其他样式保持不变 */
  flex-shrink: 0; /* 防止标题缩小，这可能导致省略号不显示 */
}

.fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fileInputLabel {
  display: inline-block;
  background-color: #28a745;
  margin-bottom: 30px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.fileInputLabel:hover {
  background-color: #218838;
}


.saveButton, .editButton {
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}

input[type="text"] {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


@media screen and (max-width: 768px) {
  .linkStyle {
    width: 20%; /* 让链接占据整个宽度 */
    max-width: 20%;
  }
}
/* SubComment.module.css */
.subcommentContainer {
  margin-left: 20px;
  padding: 8px;
  background-color: #f9f9f9;
  border-left: 3px solid #ddd;
  transition: all 0.3s ease-out;
}

.subcommentHeader {
  display: flex;
  align-items: center;
}
.actionButtonList {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
} 

.subcommentAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.subcommentName {
  font-weight: bold;
  color: #333;
}

.subcommentDate {
  margin-left: auto;
  color: #aaa;
  font-size: 0.85em;
  margin-right: 20px;
}

.subcommentText {
  margin-top: 4px;
  line-height: 1.4;
  color: #666;
}

.replyArrow {
  color: #ccc; /* Light grey color */
  font-size: 0.8em; /* Smaller size */
  margin: 0 5px; /* Adequate spacing around the arrow */
}

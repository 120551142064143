/*
  avatar
*/
.posts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.3rem;
}

.items-container {
  display: flex;
  flex-flow: column;
  align-content: space-between;
}

.items-container img {
  width: 100%;  /* makes image take full width of its container */
  height: auto; /* keeps image aspect ratio */
  object-fit: cover;
  overflow: hidden;
}

.post-item {
  display: flex;
  flex-flow: column;
  position: relative;
  padding-top: 0.2rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-bottom: 0.1rem;
  border-radius: 2%;
  margin-bottom: 0.3rem;
  counter-increment: count;   
  transition: transform 500ms;
}
.post-item:hover {
  opacity: 90%;
}

.items-container .post-item:focus,
.items-container .post-item:hover {
  transform: scale(1.1);
  z-index: 1;
}

.post-item img{
  width: 100%;  /* makes image take full width of its container */
  height: 100%; /* keeps image aspect ratio */
  border-radius: 2%;
  object-fit: cover;
  overflow: hidden;
}

.post-item video {
  width: 100%;  
  height: auto; 
  border-radius: 3%;
  object-fit: cover;
  overflow: hidden;
}

.post-title {
  display: flex;
  justify-content: flex-start;  /* Aligns children to the left */
  width: 100%;                  /* Ensures the div takes up the full width of its container */
  align-items: center;          /* Vertically center-aligns the content */
}

.post-grid {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 2fr;
  grid-template-areas: 
    'avatar name date like';
  grid-auto-rows: auto; /* makes grid rows adjust to content height */
}

.post-avatar {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;  /* Aligns children to the left */
  width: 100%;                  /* Ensures the div takes up the full width of its container */
  align-items: center;          /* Vertically center-aligns the content */
  grid-area: avatar;
}

.post-avatar img{
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.post-name {
  grid-area: name;
  display: flex;
  justify-content: flex-start;  /* Aligns children to the left */
  width: 100%;                  /* Ensures the div takes up the full width of its container */
  align-items: center;          /* Vertically center-aligns the content */
}

.post-date {
  grid-area: date;
  display: flex;
  flex-flow: row;
  grid-area: date;
  justify-content: center;      /* Aligns children to the center horizontally */
  width: 100%;                  /* Ensures the div takes up the full width of its container */
  align-items: center;          /* Vertically center-aligns the content */
  color: dimgrey;
}

.post-like {
  display: flex;
  flex-flow: row;
  grid-area: like;
  justify-content: center;      /* Aligns children to the center horizontally */
  width: 100%;                  /* Ensures the div takes up the full width of its container */
  align-items: center;          /* Vertically center-aligns the content */
  transform: scale(0.8);
}

  
.post-avatar-name-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-template-rows: 3fr 1fr;
  grid-gap: 0.3rem;
  padding: 1rem;
}

.avatar-post-img {
  border-radius: 50%;
  cursor: pointer;
}

.video-thumbnail {
  position: relative;
  display: inline-block;
}

.video-thumbnail img {
  display: block; /* 确保没有额外空间 */
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px; 
  height: 50px; 
  background: url('../../img/playIcon.png') no-repeat center center;
  background-size: cover;
  opacity: 0.5; 
  scale: 1;
  zoom: 1.5;
  cursor: pointer; 
}


@media (max-width: 700px) {

  .posts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.3rem;
  }

  .items-container {
    display: flex;
    flex-flow: column;
    align-content: space-between;
    
  }

  .items-container img {
    width: 100%;  /* makes image take full width of its container */
    height: auto; /* keeps image aspect ratio */
    object-fit: cover;
    overflow: hidden;
  }

  .post-item {
    display: flex;
    flex-flow: column;
    position: relative;
    padding-top: 0.2rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-bottom: 0.1rem;
    border-radius: 3%;
    margin-bottom: 0.3rem;
    counter-increment: count;  
  }

  .post-item img{
    width: 100%;  /* makes image take full width of its container */
    height: 100%; /* keeps image aspect ratio */
    border-radius: 3%;
    object-fit: cover;
    overflow: hidden;
  }

  /* cat-profiles Page */
  .cat-profile {
    grid-template-columns: 1fr;
    text-align: left;
  }

  .cat-profile ul {
    display: none;
  }

  /* cat-profile Page */

.post-grid {
  display: grid;
  grid-template-columns: 1.5fr 3fr 2fr 2fr;
  grid-template-areas: 
    'avatar name date like';
  grid-auto-rows: auto; /* makes grid rows adjust to content height */
}

  .post-avatar {
    text-align: left;
    justify-content: center;
    grid-area: avatar;
  }

  .post-avatar img{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
  }

  .post-name {
    text-align: left;
    justify-content: flex-start;
    font-size: 0.7rem;
  }

  .post-date {
    grid-area: date;
    text-align: right;
    font-size: 0.5rem;
  }
  .post-cover {
    grid-area: cover;
  }
  .post-cover img {
    width: 100%;  /* makes image take full width of its container */
    height: auto; /* keeps image aspect ratio */
    object-fit: cover;
  }

  .post-like {
    display: flex;
    flex-flow: row;
    grid-area: like;
    justify-content: center;      /* Aligns children to the center horizontally */
    width: 100%;                  /* Ensures the div takes up the full width of its container */
    align-items: center;          /* Vertically center-aligns the content */
    }
}



@media (max-width: 700px) {
  

  /* cat-profiles Page */
  .cat-profile {
    grid-template-columns: 1fr;
    text-align: left;
  }

  .cat-profile ul {
    display: none;
  }

  /* cat-profile Page */

  .cat-profile-top img,
  .cat-profile img {
    margin: auto;
  }

  .cat-profile-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-areas: 
      'name name name option'
      'about about about avatar';
  }

  .cat-profile-avatar {
    text-align: center;
    justify-content: center;
    grid-area: avatar;
  }
 .cat-profile-about {
    grid-area: about;
  }

  .cat-profile-option {
    display: flex;
    grid-area: option;
    text-align: right;
    justify-content: flex-end;
  }
  .cat-profile-top {
    grid-area: name;
  }

  .line {
    height: 1px;
    background: #e9e8e8;
    margin: 1.5rem 0;
  }
}
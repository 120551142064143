/*
  avatar
*/
.posts-forms-list {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin-bottom: 1rem;
}


.video-preview {
  max-width: 30rem; /* 设置最大宽度为400px */
  max-height: 30rem; /* 设置最大高度为400px */
  width: auto;
  border-radius: 3%;
  height: auto; /* 使视频自适应其容器高度并保持比例 */
}

.video-preview-small {
  max-width: 10rem; /* 设置最大宽度为400px */
  max-height: 10rem; /* 设置最大高度为400px */
  border-radius: 5%;
  height: auto; /* 使视频自适应其容器高度并保持比例 */
  cursor: pointer;
}

.website-icon-medium {
  width: 3rem;
  height: 3rem;
  border-radius: 10%;
  cursor: pointer;
}

.posts-list-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  margin-bottom: 1rem;
}

.posts-list-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3rem;
  font-family: 'Futura'; 
  font-weight: 900;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  column-gap: 0.3rem;
  line-height: 1.2;
}

.posts-list-header-search-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.post-form-preview-loading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.float-button-add-post {
  background-color: var(--primary-light-color);
  color: 'white';
  zoom: 1.3;
  z-index: 1000;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.post-form{
  padding: 0.5rem;
  @media screen {
    overflow-y: auto;
    max-height: 50vh;
  }
}

@media (max-width: 700px) {

  .posts-list-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    column-gap: 0.1rem;
    line-height: 1.2;
  }

  .website-icon-medium {
    width: 2rem;
    height: 2rem;
    border-radius: 10%;
    cursor: pointer;
  }

}
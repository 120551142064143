.profile-follow-button-container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  zoom: 0.8;
}

.profile-top-profile-button-container {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.cat-profile {
  grid-template-columns: 1fr;
  text-align: left;
}

.cat-profile ul {
  display: none;
}

/* cat-profile Page */

.cat-profile-top img,
.cat-profile img {
  margin: auto;
}

.cat-profile-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 
    'name name name name'
    'about about avatar avatar';
}

.cat-profile-avatar {
  text-align: center;
  justify-content: center;
  grid-area: avatar;
}
.cat-profile-about {
  grid-area: about;
}

.cat-profile-option {
  grid-area: option;
  text-align: right;
}
.cat-profile-name {
  grid-area: name;
}

.line {
  height: 1px;
  background: #e9e8e8;
  margin: 1.5rem 0;
}

.profile-follow-stats-container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 10px 0;
}

.profile-follow-stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.profile-follow-stats-count {
  font-size: 20px; /* Adjust size as needed */
  font-weight: bold;
}

.profile-follow-stats-name {
  font-size: 16px; /* Adjust size as needed */
}


@media (max-width: 700px) {

  .profile-top-profile-button-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 100%;
    zoom: 0.7;
  }

  /* cat-profiles Page */
  .cat-profile {
    grid-template-columns: 1fr;
    text-align: left;
  }

  .cat-profile ul {
    display: none;
  }

  /* cat-profile Page */

  .cat-profile-top img,
  .cat-profile img {
    margin: auto;
  }

  .cat-profile-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-areas: 
      'name name name option'
      'about about about avatar';
  }

  .cat-profile-avatar {
    text-align: center;
    justify-content: center;
    grid-area: avatar;
  }
 .cat-profile-about {
    grid-area: about;
  }

  .cat-profile-option {
    grid-area: option;
    text-align: right;
  }
  .cat-profile-top {
    grid-area: name;
  }

  .line {
    height: 1px;
    background: #e9e8e8;
    margin: 1.5rem 0;
  }
}
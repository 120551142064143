.authContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
}

.largeText {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.form {
  width: 100%;
}

.formGroup {
  position: relative; /* Add this line */
  margin-bottom: 1rem;
}

.inputField {
  width: 100%;
  height: 40px;
  padding: 12px 40px 12px 16px; /* Increased right padding to make room for the eye icon */
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  position: relative;
  box-shadow: #444 0px 0px 1px;
}

.passwordIcon {
  position: absolute;
  top: 40%;
  right: 10px; /* Adjust the value as needed */
  transform: translateY(-50%); /* Centers the icon vertically */
  cursor: pointer;
  opacity: 0.5;
  /* Add styling for your icons here */
}

.inputField[type="password"] {
  padding-right: 40px; /* Extra right padding to make room for the eye icon inside the password input */
}

.togglePassword {
  position: absolute;
  inset: 0;
  margin: auto;
  height: 20px;
  width: 20px;
  right: 16px;
  cursor: pointer;
}

.loginButton {
  width: 100%;
  background-color: var(--primary-light-color);
  border: none;
  padding: 0.75rem;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #444;
}

.bottomText {
  text-align: center;
  margin-top: 1rem;
}

.signUpLink {
  color: #0066ff;
  text-decoration: none;
}

.forgetPasswordLink {
  color: #0066ff;
  text-decoration: none;
}

.signUpLink:hover {
  text-decoration: underline;
}

/* ...existing styles... */

.newAccountText {
  text-align: center;
  margin: 1rem 0;
  font-size: 0.9rem;
  color: #333;
}

.registerButton {
  display: block; /* or 'inline-block' if you prefer */
  width: 100%; /* Adjust width as needed */
  background-color: #28a745; /* Adjust the color as needed */
  border: none;
  padding: 0.75rem;
  color: white;
  border-radius: 5px;
  text-align: center;
  text-decoration: none; /* Remove underline from links */
  margin-bottom: 1rem; /* Optional spacing */
  cursor: pointer;
}

.registerButton:hover {
  background-color: #218838; /* Darken the button on hover */
}

.disabledButton {
  background-color: #cccccc; /* Grayed out */
  color: #666666; /* Dark gray text */
  cursor: not-allowed;
}

.errorMessage {
  color: transparent; /* Initially make the error message transparent */
  font-size: 14px; /* Smaller font size for errors */
  height: 18px; /* Reserve space for the message; adjust as needed */
  margin-top: -10px;
  transition: color 0.3s; /* Smooth transition for color change */
}

.errorMessage.visible {
  color: red; /* Make the text red when error should be visible */
}


.bottomText {
  color: #666;
  font-size: 16px; /* Adjust as needed */
  text-align: center; /* Center the text */
}

.labelTerms {
  margin-left: 8px;
  font-size: 14px; /* Adjust font size as needed */
}

/* Style adjustments for checkbox if needed */
input[type="checkbox"] {
  margin-top: 0.5em;
}

/* auth.module.css */

.socialLogin {
  text-align: center;
  margin-top: 20px;
}

.googleLoginButton {
  background-color: #fff;
  color: #757575;
  width: 250px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.googleLoginButton:hover {
  box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
}

.googleLoginButton:focus {
  outline: none;
  border: 1px solid transparent;
}

.googleIcon {
  width: 30px;
  height:30px;
  margin-right: 10px;
}

/* Use the Google brand colors */
.googleText {
  color: #5F6368;
}

/* Add this to your auth.module.css file */

.divider {
  display: flex;
  width: 100%;
  flex-flow: row;
  align-items: center;
  text-align: center;
  margin: 0;
}

.dividerLine {
  flex-grow: 1; 
  border: none; 
  width: 100%;
  height: 1px;
  background-color: #ccc; 
  margin: 0 10px; 
}

.dividerText {
  color: #5F6368; /* or any color of your choice */
  padding: 0 10px;
}



/* ...remaining styles... */

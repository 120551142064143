
.line {
  height: 1px;
  background: #e9e8e8;
  margin: 1.5rem 0;
}

.notifications-container {
  position: relative;
}

.notification-buttons {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.notification-btn {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.notification-btn.active {
  background-color: var(--primary-color);
  color: white;
}

.slider {
  position: absolute;
  bottom: -2px; /* Adjust as needed to match your design */
  left: 0;
  width: 50%; /* Assuming two buttons of equal width */
  height: 2px;
  background-color: var(--primary-color);
  transition: all 0.3s ease;
}

.slider.unread {
  transform: translateX(0%);
}

.slider.read {
  transform: translateX(100%);
}

@media (max-width: 700px) {

}
.termsContainer {
  padding: 20px;
}

h1, h2, h3 {
  color: #333;
}

p, li {
  color: #666;
  line-height: 1.6;
}

ul {
  margin-left: 20px;
}

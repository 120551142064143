@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(40%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.initial-position {
  transform: translateY(40%);
  opacity: 0; 
}

.finished-position {
  transform: translateY(0%);
  opacity: 1; 
}

.slide-in-up {
  animation: slideInUp 0.5s ease-in-out forwards;
}

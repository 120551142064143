/* CreatePostModal.module.css */
.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.modalContent img {
  width: 40%;
  height: auto;
  border-radius: 10px;
}

.modalButton {
  width: 100%;
  margin: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
}

.footerButton {
  /* Styles for your footer buttons if needed */
}

.imageIcon {
  /* Adjust size as needed */
  width: 24px; 
  height: 24px;
}

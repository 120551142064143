.inputSearch {
  padding: 8px 16px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th,
.table td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: var(--primary-color);
  color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.approved {
  color: gray;
}

.button {
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #218838;
}
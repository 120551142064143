/* App.module.css */
.app {
  text-align: center;
  background: linear-gradient(100deg, rgba(14, 158, 147,1) 10%, rgba(87, 0, 126,1) 50%, rgba(14, 158, 147,1) 90%);
  background-size: 200% 100%;
  background-position-x: 150%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  animation: loading 1.5s infinite;
  overflow-x: hidden;
}

@keyframes loading {
  to {
    background-position-x: -50%;
  }
}

.appHeader {
  margin-top: 10rem;
  height: 100%;
  width: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
}

.title {
  color: #ffbe0b;
  width: 80vw;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  animation: floating 1.5s infinite;
}

.description {
  margin-bottom: 2rem;
  color: #ccc;
  width: 80vw;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  animation: floating 1.5s infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.buttons {
  display: flex;
  width: 80vw;
  flex-direction: row;
  gap: 24px;
}

.button {
  width: 200px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 20px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.5s ease-in-out;
}

.button:hover {
  background-color: #0056b3;
}

.tryChatGPT {
  background-color: #06d6a0;
}

.tryChatGPT:hover {
  background-color: #05b78e;
}

.readMore {
  background-color: #118ab2;
}

.readMore:hover {
  background-color: #0f769f;
}

.activationForm {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activationForm input {
  padding: 8px;
  margin-bottom: 10px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.hideButton {
  transform: translateX(-100%);
  visibility: hidden;
  display: none;
}

.accessButtons {
  display: flex;
  align-items: center;
  width: 100%;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  visibility: hidden;
  gap: 10px;
}

.showAccessButtons {
  transform: translateX(0%);
  visibility: visible;
}

.activationInput {
  margin-right: 10px;
  height: 55px;
  width: 50%;
  font-size: 24px;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.1);
}

.activateButton, .requestAccessButton {
  height: 40px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.activateButton:hover, .requestAccessButton:hover {
  background-color: #4cae4c;
}

@media screen and (max-width: 768px) {
  .appHeader {
    margin-top: 5rem;
    width: 130%;
  }

  .title, .description {
    width: 100vw;
  }

  .buttons {
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    width: 100%;
  }

  .button {
    height: 50px;
  }

  .tryChatGPT {
    width: 80%;
  }

  .accessButtons {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    width: 110vw;
  }

  .activationInput {
    width: 80%;
  }
}
